
import { permits } from "./api";
import { TemporalInstantInterval } from "$utils/temporal";

export {
    permits
};

export function cancelled(permit: Permit): boolean {
    return !!permit?.cancelled;
}
export function revoked(permit: Permit): boolean {
    return !!permit?.revoked;
}

const statustitles: Record<string, string> = {
    "valid": "Active",
    "revoked": "Revoked",
    "cancelled": "Cancelled",
    "canceled": "Canceled",
    "changed": "Changed",
    "invalid": "Ended",
    "invalidating": "Ending Soon",
    "pending": "Upcoming",
    "prevalid": "Upcoming",
    "validating": "Starting Soon",
}

export function statusTitle(permit: Permit, now: Temporal.Instant): string {

    if (permit.revoked) return permit.revoked.title;

    var life = lifecycle(permit, now);

    //if(life === "invalid" && revoked(permit)) life = "revoked";
    return statustitles[life] || "Unknown";
}

const stages = ["pending", "prevalid", "validating", "invalidating", "valid", "invalid"];

export function lifecycle(permit: Permit, now: Temporal.Instant): string {
    // custom cases...do we need?
    if (cancelled(permit)) return "cancelled";
    if (permit.revoked) return permit.revoked.title.toLowerCase();

    for (const stage of stages) {
        const interval = permit.lifecycle[stage];
        if (!interval) continue;
        if (!interval.includes("/")) continue;
        if (TemporalInstantInterval.from(interval)?.contains(now)) return stage;
    }
    return "indeterminate";
}