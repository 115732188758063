<script lang="ts">
  import Time from "$components/temporal/Time.svelte";
  import Loading from "$components/util/Loading.svelte";
  import { datetime } from "$utils/temporal";

  export let permit: Permit;
  $: issued = Temporal.Instant.from(permit.issued.datetime).toZonedDateTimeISO(
    permit.timezone
  );
  // $: revoked = permit?.revoked
  //   ? Temporal.Instant.from(permit.revoked.datetime).toZonedDateTimeISO(
  //       permit.timezone
  //     )
  //   : null;
</script>

{#if permit}
  <dl>
    <dt>Recorded</dt>
    <dd>
      {#if issued}
        <time datetime={permit.issued.datetime}
          ><abbr title={issued.toString()}>{datetime(issued)}</abbr></time
        >
      {/if}
    </dd>
    {#if permit.predecessor}
      <!-- <dt>Changed</dt> -->
      <dd><a href={permit.predecessor.url}>See Previous Permit</a></dd>
    {/if}
    <dt>Record #</dt>
    <dd><data value={permit.id}>{permit.number}</data></dd>
    <!-- {#if permit.revoked}
      <dt>{permit.revoked.title}</dt>
      <dd>
        {#if revoked}
          <Time datetime={permit.revoked.datetime} timezone={permit.timezone} />
        {/if}
      </dd>
    {/if} -->
    {#if !permit.cancelled}
      <dt>Usage</dt>
      <dd>
        {permit.exempt
          ? "Not counted against limits"
          : "Counted against limits"}
      </dd>
    {/if}
    <dt>Synced</dt>
    <dd>
      {#if permit.generated}
        <Time datetime={permit.generated} />
      {:else}
        <Loading />
      {/if}
    </dd>
  </dl>
{/if}
