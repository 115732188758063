import { derived, writable, type Readable, readable } from "svelte/store";
import {
  fetchDirectory,
  fetchGeoProperties,
  fetchProperties,
  fetchProperty,
  fetchSearchProperties,
  fetchSpaceStatus,
  fetchUnit,
  fetchUnits,
} from "./api";
import { params, param } from "./params";


import store from "store/dist/store.modern";
import { position, search } from "./uistores";

export const propertyId = param("property");


export const nearbyProperties = derived<typeof position, Properties>(
  position,
  ($position, set) => {
    if (!$position.coords)
      return set({
        ...$position,
        loading: false,
      }); // nothing more to do here

    // set({
    //   ...{
    //     ...$position,
    //   },
    //   loading: true,
    // });

    //const key = `${$position.position.coords.longitude},${$position.position.coords.latitude},${$position.position.coords.accuracy}`;

    //const results = positions[key] || (positions[key] = (await fetchAndStoreGeoProperties($position.position.coords.longitude, $position.position.coords.latitude, $position.position.coords.accuracy)).filter(property => property.amenities.items.includes("parking")));

    fetchGeoProperties(
      $position.coords.longitude,
      $position.coords.latitude,
      $position.coords.accuracy
    ).then(function (json) {
      const results = Object.values(json.properties.items).filter(
        (property: Property) => property.amenities.parking
      ) as Property[];
      set({
        ...$position,
        items: results,
        loading: false,
        status: "complete",
      });
    });
  },
  null
);

const searches = {};
// export const searchProperties = derived<typeof search, Properties>(
//   search,
//   (query, set) => {
//     if (!query || query.length < 4)
//       return set({
//         query,
//         count: 0,
//         items: [],
//         loading: false,
//       });

//     // set({
//     //   query,
//     //   loading: true,
//     // });

//     const cached = searches[query];

//     if (cached)
//       return set({
//         query,
//         loading: false,
//         count: cached.length,
//         items: cached,
//       });

//     fetchSearchProperties(query).then(function (json) {
//       const results = (searches[query] = Object.values(
//         json.properties.items
//       ).filter(
//         (property: Property) => property.amenities.parking
//       )) as Property[];
//       set({
//         query,
//         loading: false,
//         count: results.length,
//         items: results,
//       });
//     });
//   },
//   {
//     count: 0,
//     items: [],
//     loading: false,
//   }
// );

// does this have memory storage implications?
export function propertySearch(query: Readable<string>) {
  return derived<typeof query, Properties>(
    query,
    ($query, set) => {
      if (!$query || $query.length < 4)
        return set({
          query: $query,
          count: 0,
          items: [],
          loading: false,
        });

      // set({
      //   query,
      //   loading: true,
      // });

      const cached = searches[$query];

      if (cached)
        return set({
          query: $query,
          loading: false,
          count: cached.length,
          items: cached,
        });

      set({
        query: $query,
        loading: true,
      });

      fetchSearchProperties($query).then(function (json) {
        const results = (searches[$query] = Object.values(
          json.properties?.items ?? {}
        )
          // .filter(
          //   (property: Property) => property.amenities.parking
          // )
        ) as Property[];
        set({
          query: $query,
          loading: false,
          count: results.length,
          items: results,
        });
      });
    },
    {
      count: 0,
      items: [],
      loading: false,
    }
  );
}

export const propertyIds = writable(store.get("properties", {}));
// write to backing store
propertyIds.subscribe(($propertyIds) => {
  //logger("writing properties to local storage", value);
  store.set("properties", $propertyIds);
});
// reset param
params.subscribe(($value) => {
  if ($value && $value.reset) propertyIds.set({});
});
// maybe this is a better derived store
propertyId.subscribe(async (value) => {
  if (!value) return;
  propertyIds.update((prev) =>
    Object.assign({}, prev, {
      [value]: Temporal.Now.instant().toString(),
    })
  );
});

// export const unit = derived<[typeof unitId], Unit>(
//   [unitId],
//   function updater([$unitId], set) {
//     if (!$unitId) return set(null);
//     fetchUnit($unitId)
//       .then(function (json) {
//         return json.items[$unitId];
//       })
//       .then(set);
//     // fetch unit
//     //fetchProperty($propertyId).then(set);
//   }
// );

export const property = derived<[typeof propertyId], Property>(
  [propertyId],
  function updater([$propertyId], set) {
    if (!$propertyId) return set(null);
    fetchProperty($propertyId).then(set);
  }
);

export const units = derived<typeof property, Units>(
  property,
  ($property, set) => {
    const $propertyId = $property?.id;
    if (!$propertyId) return set(null);

    fetchUnits($propertyId)
      .then(function (json) {
        const meta = json.units["for"][$propertyId];
        const items = meta.items;

        for (const [k, v] of Object.entries(items)) {
          items[k] = json.items[v as string] ?? json.items[k] ?? v;
        }

        return meta;
      })
      .then(set);
  }
);

export const unitsByLevel = derived<[typeof units], ByLevels<Unit>>(
  [units],
  ([$units]) => {
    if (null == $units) return null;

    const levels: Record<string, ByLevel<Unit>> = {
      // outside: {
      //   level: "outside",
      //   "level:ref": "Outside",
      //   count: 0,
      //   available: 0,
      // },
    };

    for (const item of Object.values($units?.items ?? {}) as any[]) {
      for (let l of item.level?.split ? item.level.split(";") : [""]) {
        if ("" == l) l = "outside";
        if (!levels[l])
          levels[l] = {
            "level:ref": "outside" === l ? "Outside" : `Floor ${l}`,
            items: {},
            level: l,
            count: 0,
            available: 0,
          };
        levels[l].count++;
        if (item["level:ref"]) levels[l]["level:ref"] = item["level:ref"];
        if (false !== item.permitted?.available) {
          levels[l].items[item.id] = item;
          levels[l].available++;
        }
      }
    }

    return levels;
  }
);


export const pastProperties = derived<[typeof propertyIds], Properties>(
  [propertyIds],
  ([$ids], set) => {
    logger("properties.deriving", $ids);
    //if(!$ids) return null;
    //logger("properties=", ids);

    if (null == $ids)
      return set({
        count: 0,
        items: [],
      });

    fetchProperties($ids).then(function (json) {
      set({
        items: Object.values(json.properties?.items ?? {}).filter(
          (property: Property) => property && property.amenities.parking
        ) as Property[],
      });
    });
  },
  {
    count: 0,
    items: [],
  }
);

// export const properties = derived<
//   [typeof searchProperties, typeof nearbyProperties, typeof pastProperties],
//   Properties
// >(
//   [searchProperties, nearbyProperties, pastProperties],
//   ($groupedProperties: Properties[]) => {
//     const items: Property[] = [];
//     for (const properties of $groupedProperties) {
//       if (!properties?.items) continue;
//       items.push(...Object.values(properties.items));
//     }

//     return {
//       count: items.length,
//       items,
//     };
//   },
//   {
//     count: 0,
//     items: [],
//   }
// );
