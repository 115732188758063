<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import TextField from "./TextField.svelte";
  import Form from "./Form.svelte";
  export let value: string | nullish = "";
  export let name: string = "q";
  export let title: string | nullish = null;
  export let placeholder: string | nullish = null;
  export let searching: boolean = false;
  const events = createEventDispatcher<{
    change: {
      name: string;
      value: string;
    };
  }>();
  let className: string = "";
  export { className as class };
  export let spellcheck: boolean | "true" | "false" | nullish = false;

  function change(name: string, newvalue: string) {
    if (newvalue === value) return;
    value = newvalue;
    if (name)
      events("change", {
        name,
        value,
      });
  }
</script>

<Form
  class={["search", className, true === searching && "searching"]
    .filter(Boolean)
    .join(" ")}
  on:reset={(e) => change(name, "")}
>
  <TextField
    type="search"
    {name}
    {title}
    {placeholder}
    {value}
    required
    autocomplete="off"
    {spellcheck}
    on:change={({ detail }) => change(detail.name, detail.value)}
  />{#if true === searching}
    <figure class="activity" />
  {:else}
    <button disabled={!value} type="reset">clear</button>
  {/if}
  <slot />
</Form>
<!-- <form
  class={["search", className].filter(Boolean).join(" ")}
  class:searching={true === searching}
  on:submit|preventDefault
  on:reset={(e) => change(name, null)}
>
  
</form> -->
