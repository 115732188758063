<script lang="ts" context="module">
  import { indefinite, instant } from "$utils/temporal";
  const now = instant({ seconds: 30 });
</script>

<script lang="ts">
  import Time from "$components/temporal/Time.svelte";

  import {
    TemporalZonedDateTimeInterval,
    iso,
    datetime,
  } from "$utils/temporal";
  import PermitValidStatus from "./PermitValidStatus.svelte";

  export let permit: Permit;
  // cancelled
  // revoked
  // pending
  let interval = TemporalZonedDateTimeInterval.from(
    permit?.valid.interval,
    permit.timezone
  );

  $: interval = TemporalZonedDateTimeInterval.from(
    permit?.valid.interval,
    permit.timezone
  );
  $: pending =
    !!interval?.minimum &&
    Temporal.ZonedDateTime.compare($now, interval.minimum) < 0;
  $: finite = !!interval?.maximum && !indefinite(interval.maximum);
  $: forever = !!interval?.maximum && indefinite(interval.maximum);
</script>

<dl>
  <dt>Status</dt>
  <dd><PermitValidStatus {permit} /></dd>
</dl>
{#if permit?.revoked}
  <dl class="subjects">
    <dt>{permit.revoked.title}</dt>
    <dd>
      <Time datetime={permit.revoked.datetime} />
      <!-- <time datetime={permit.revoked.datetime}
        ><abbr title={parseISO(permit.revoked.datetime).toString()}
          >{format(
            utcToZonedTime(parseISO(permit.revoked.datetime), permit.timezone),
            "h:mm a EEE MMM d yyyy",
            {
              timeZone: permit.timezone,
            }
          )}</abbr
        ></time
      > -->
    </dd>
    {#if permit.successor}
      <!-- <dt>Changed</dt> -->
      <dd><a href={permit.successor.url}>See Updated Permit</a></dd>
    {/if}
  </dl>
{:else if finite || pending}
  <dl>
    <dt>Start</dt>
    <dd>
      <Time datetime={interval?.minimum} />
    </dd>
    {#if finite}
      <dt>End</dt>
      <dd>
        {#if interval?.maximum}
          <Time datetime={interval?.maximum} indefinite="when revoked" />
        {:else}
          <time datetime="">when revoked</time>
        {/if}
      </dd>
    {/if}
  </dl>
{/if}
<!--  -->
